import PropTypes from "prop-types";
import React from "react";

function TextDiv({children, className}) {
    return (
        <div className={"flex flex-col p-8 " + className}>
            {children}
        </div>
    )
}

TextDiv.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
}

export default TextDiv;