import React from "react";
import PropTypes from "prop-types";

const UnorderedList = (props) => {
    return (
        <ul className={"list-disc list-outside " + (props.className ?? "")}>
            {props.children}
        </ul>
    )
}

UnorderedList.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
}

export default UnorderedList;
