import React from "react";
import PropTypes from "prop-types";

function SectionRow({children}) {
    return (
        <div className="flex flex-col md:flex-row max-w-6xl justify-center mx-auto">
            {children}
        </div>
    )
}

SectionRow.propTypes = {
    children: PropTypes.node.isRequired
}

export default SectionRow;
