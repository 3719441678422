import React from "react"
import Layout from "../components/Layout"
import SVGCurve from "../components/SVGCurve";
import Section, {SectionHeader} from "../components/Section";
import SectionRow from "../components/SectionRow";
import TextDiv from "../components/TextDiv";
import TextParagraph from "../components/TextParagraph";
import UnorderedList from "../components/UnorderedList";
import ExternalLink from "../components/ExternalLink";

export default () => {
    return (
        <Layout>
            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <TextParagraph>
                            Thank you for visiting my website! I am an Industrial Engineer with leadership
                            experience in commercial manufacturing and production optimization. Here you can find
                            information
                            regarding my professional background and interests. If you have any questions, please
                            contact me on <ExternalLink to="https://www.linkedin.com/in/catherine-flores-9b1842137/">Linked
                            In</ExternalLink>.
                        </TextParagraph>
                    </TextDiv>
                </SectionRow>

            </Section>
            <SVGCurve transition="mid-light" direction="right-slope"/>
            <Section shade="light">
                <SectionRow>
                    <TextDiv className="w-full">
                        <SectionHeader>Experience Overview</SectionHeader>
                        <UnorderedList className="ml-4 mt-2">
                            <li>Two years experience in commercial manufacturing in Venezuela</li>
                            <li>Leadership experience in quality management, auditing, and continuous improvement</li>
                            <li>Experience with ISO 9000, 9001, 17025 compliance</li>
                            <li>B.S. in Industrial Engineering, 2018, University of Carabobo, Valencia, Venezuela</li>
                            <li>Languages: Spanish (native), English (working proficiency)</li>
                            <li>Linked In: <ExternalLink
                                to="https://www.linkedin.com/in/catherine-flores-9b1842137/">Catherine
                                Flores</ExternalLink></li>
                            <li>Resume: Available upon request</li>
                        </UnorderedList>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve transition="light-mid" direction="right-slope"/>
            <Section shade="mid">
                <SectionRow>
                    <TextDiv className="w-full">
                        <SectionHeader>Introductory Video</SectionHeader>
                        <div className="video-container mt-4">
                            <iframe src="https://www.youtube.com/embed/8z-OeMHxTP0"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve transition="mid-light" direction="down"/>
        </Layout>
    )
}
