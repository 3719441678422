import PropTypes from "prop-types";
import React from "react";

function TextParagraph({children, className}) {
    let classNameString = "md:text-xl font-normal " + className
    return (
        <p className={classNameString}>
            {children}
        </p>
    )
}

TextParagraph.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
}

export default TextParagraph;